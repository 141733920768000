<template>
  <div>

    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >
      <b-form>
        <b-overlay
          :show="showOverlay"
          rounded="sm"
          no-fade
        >

          <b-row>
            <!-- Field: Nome Completo -->
            <b-col
              cols="12"
              md="7"
            >
              <validation-provider
                #default="{ errors }"
                name="Nome"
                rules="required"
                immediate
              >
                <b-form-group
                  label="Nome"
                  label-for="document-template-name"
                >
                  <b-form-input
                    id="document-template-name"
                    v-model="documentTemplate.name"
                    :state="errors.length > 0 ? false : null"
                    trim
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="2"
            >
              <small>{{ documentTemplate.showHeader ? 'Com' : 'Sem' }} Cabeçalho</small>
              <b-form-checkbox
                v-model="documentTemplate.showHeader"
                checked="true"
                class="custom-control-primary mt-1"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="EyeIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-col>

            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Tipo"
                label-for="document-template-name"
              >
                <v-select
                  v-model="documentTemplate.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  :options="typeOptions"
                  :reduce="option => option.value"
                  input-id="document-template-name"
                >
                  <template #no-options="{}">
                    Nenhum resultado encontrado.
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="9"
              class="document-template-editor"
            >
              <ckeditor
                v-model="documentTemplate.content"
                :editor="editor"
                :config="editorConfig"
                @ready="onReady"
              />
            </b-col>

            <b-col
              cols="12"
              md="3"
            >
              <h4>Variáveis de Dados do Paciente</h4>
              <span
                v-for="(variable, i) in patientVariables"
                :key="`${i}_patientVariables`"
                class="text-sm cursor-pointer"
                @click="copyVariable(variable.variable)"
              >
                <!-- TODO colocar um tooltip -->
                {{ variable.label }} <br>
              </span>

              <h4 class="mt-1">
                Variáveis de Endereço do paciente
              </h4>
              <span
                v-for="(variable, i) in patientAddressVariables"
                :key="`${i}_patientAddressVariables`"
                class="text-sm cursor-pointer"
                @click="copyVariable(variable.variable)"
              >
                <!-- TODO colocar um tooltip -->
                {{ variable.label }} <br>
              </span>

              <h4 class="mt-1">
                Variáveis de Responsável do paciente
              </h4>
              <span
                v-for="(variable, i) in patientResponsibleVariables"
                :key="`${i}_patientResponsibleVariables`"
                class="text-sm cursor-pointer"
                @click="copyVariable(variable.variable)"
              >
                <!-- TODO colocar um tooltip -->
                {{ variable.label }} <br>
              </span>

              <h4 class="mt-1">
                Variáveis de Endereço do Responsável do paciente
              </h4>
              <span
                v-for="(variable, i) in patientResponsibleAddressVariables"
                :key="`${i}_patientResponsibleAddressVariables`"
                class="text-sm cursor-pointer"
                @click="copyVariable(variable.variable)"
              >
                <!-- TODO colocar um tooltip -->
                {{ variable.label }} <br>
              </span>

            </b-col>

          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="9"
            />

            <b-col
              cols="12"
              md="3"
              class="mt-2"
            >
              <b-button
                variant="primary"
                :disabled="invalid"
                block
                @click="save(documentTemplate)"
              >
                Salvar
              </b-button>
            </b-col>
          </b-row>
        </b-overlay>

      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BButton, BOverlay,
  BFormCheckbox,
} from 'bootstrap-vue'
import { mask } from 'vue-the-mask'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'

import CKEditor from '@ckeditor/ckeditor5-vue2'

// ⚠️ NOTE: We don't use @ckeditor/ckeditor5-build-classic any more!
// Since we're building CKEditor from source, we use the source version of ClassicEditor.
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import ClassicEditor from '@/util/ckeditor5/src/ckeditor'
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br'

import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold'
import Code from '@ckeditor/ckeditor5-basic-styles/src/code'
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed'
import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'
import Indent from '@ckeditor/ckeditor5-indent/src/indent'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Link from '@ckeditor/ckeditor5-link/src/link'
import List from '@ckeditor/ckeditor5-list/src/list'
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import AutoImage from '@ckeditor/ckeditor5-image/src/autoimage'
import CKFinderUploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter'
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices'
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace'
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor'
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor'
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily'
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize'
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight'
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize'
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock'
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat'
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters'
import SpecialCharactersArrows from '@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows'
import SpecialCharactersCurrency from '@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency'
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials'
import SpecialCharactersLatin from '@ckeditor/ckeditor5-special-characters/src/specialcharacterslatin'
import SpecialCharactersMathematical from '@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical'
import SpecialCharactersText from '@ckeditor/ckeditor5-special-characters/src/specialcharacterstext'
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount'

import useMain from './useMain'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BButton,
    BOverlay,
    BFormCheckbox,

    ValidationProvider,
    ValidationObserver,
    ckeditor: CKEditor.component,
    vSelect,
  },

  directives: {
    mask,
  },

  props: {
    documentTemplate: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    localize('pt_BR') // vee-validate messages

    const {
      showOverlay,
      typeOptions,
      save,
    } = useMain(emit, props)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const ckEd = ref(null)

    // Variáveis do paciente
    const companyVariables = ref([
      { variable: '[LOGO_EMPRESA]', label: 'Logo da empresa' },
    ])

    // Variáveis do paciente
    const patientVariables = ref([
      { variable: '[DIA]', label: 'Dia' },
      { variable: '[MES]', label: 'Mês' },
      { variable: '[ANO]', label: 'Ano' },
      { variable: '[NOME_PACIENTE]', label: 'Nome' },
      { variable: '[DATA_CADASTRO]', label: 'Data de cadastro' },
      { variable: '[DATA_NASCIMENTO]', label: 'Data de nascimento' },
      { variable: '[IDADE]', label: 'Idade' },
      { variable: '[CPF_PACIENTE]', label: 'CPF do paciente' },
      { variable: '[RG_PACIENTE]', label: 'RG do paciente' },
      { variable: '[ORGAO_EMISSOR]', label: 'Órgão emissor RG' },
      { variable: '[UF_RG]', label: 'Estado do RG' },
      { variable: '[GENERO_PACIENTE]', label: 'Gênero do paciente' },
      { variable: '[ESTADO_CIVIL_PACIENTE]', label: 'Estado civil do paciente' },
      { variable: '[TELEFONE_FIXO]', label: 'Telefone fixo' },
      { variable: '[CELULAR]', label: 'Celular' },
      { variable: '[EMAIL_PACIENTE]', label: 'E-mail' },
      { variable: '[NATURALIDADE_PACIENTE]', label: 'Naturalidade' },
      { variable: '[PROFISSAO_PACIENTE]', label: 'Profissão' },
    ])

    // ENDEREÇO PACIENTE:
    const patientAddressVariables = ref([
      { variable: '[UF_RES_PACIENTE]', label: 'Estado' },
      { variable: '[CIDADE_RES_PACIENTE]', label: 'Cidade' },
      { variable: '[RUA_RES_PACIENTE]', label: 'Rua' },
      { variable: '[NUMERO_RES_PACIENTE]', label: 'Número' },
      { variable: '[BAIRRO_RES_PACIENTE]', label: 'Bairro' },
      { variable: '[CEP_RES_PACIENTE]', label: 'CEP' },
      { variable: '[COMPL_RES_PACIENTE]', label: 'Complemento' },
    ])

    // RESPONSÁVEL PELO PACIENTE:
    const patientResponsibleVariables = ref([
      { variable: '[NOME_RESPONSAVEL]', label: 'Nome avalista' },
      { variable: '[CPF_RESPONSAVEL]', label: 'CPF avalista' },
      { variable: '[RG_RESPONSAVEL]', label: 'RG avalista' },
      { variable: '[ORGAO_EMISSOR_RG_RESP]', label: 'Órg. emissor RG avalista' },
      { variable: '[UF_RG_RESPONSAVEL]', label: 'UF RG avalista' },
      { variable: '[VINCULO]', label: 'Natureza de vinculo com o paciente' },
      { variable: '[NATURALIDADE_RESPONSAVEL]', label: 'Naturalidade avalista' },
      { variable: '[NASCIMENTO_RESPONSAVEL]', label: 'Data de nascimento avalista' },
      { variable: '[EMAIL_RESPONSAVEL]', label: 'E-mail avalista' },
      { variable: '[PROFISSAO_RESPONSAVEL]', label: 'Profissão avalista' },
      { variable: '[NUMERO_RESPONSAVEL]', label: 'Celular avalista' },
      { variable: '[ESTADO_CIVIL_RESPONSAVEL]', label: 'Estado civil avalista' },

    ])

    // ENDEREÇO RESPONSÁVEL PELO PACIENTE:
    const patientResponsibleAddressVariables = ref([
      { variable: '[UF_RES_RESPONSAVEL]', label: 'Estado' },
      { variable: '[CIDADE_RES_RESPONSAVEL]', label: 'Cidade' },
      { variable: '[RUA_RES_RESPONSAVEL]', label: 'Rua' },
      { variable: '[NUMERO_RES_RESPONSAVEL]', label: 'Número end.' },
      { variable: '[BAIRRO_RES_RESPONSAVEL]', label: 'Bairro' },
      { variable: '[CEP_RES_RESPONSAVEL]', label: 'CEP' },
      { variable: '[COMPL_RES_RESPONSAVEL]', label: 'Complemento end.' },
    ])

    const onReady = editor => {
      ckEd.value = editor
      // console.log(editor.ui.componentFactory.names())
    }

    const copyVariable = variable => {
      // INSERE A VARIÁVEL ONDE O CURSOR ESTIVER
      ckEd.value.model.change(writer => {
        const insertPosition = ckEd.value.model.document.selection.getFirstPosition()
        writer.insertText(variable, {}, insertPosition)
      })

      // COPIA A VARIÁVEL PARA A MEMÓRIA (CTRL C + CTRL V)
      // const thisIns = this
      // this.$copyText(variable).then(function () {
      //   thisIns.$vs.notify({
      //     time: 700,
      //     title: 'Success',
      //     text: 'Variável copiada.',
      //     color: 'success',
      //     iconPack: 'feather',
      //     position: 'top-center',
      //     icon: 'icon-check-circle'
      //   })
      // }, function () {
      //   thisIns.$vs.notify({
      //     title: 'Failed',
      //     text: 'Oops. Algo inesperado aconteceu. Por favor avise o suporte',
      //     color: 'danger',
      //     iconPack: 'feather',
      //     position: 'top-center',
      //     icon: 'icon-alert-circle'
      //   })
      // })
    }
    const editor = ClassicEditor

    const editorConfig = {
      language: 'pt-br',
      plugins: [
        Autoformat,
        BlockQuote,
        Bold,
        Code,
        CodeBlock,
        Essentials,
        GeneralHtmlSupport,
        Heading,
        HtmlEmbed,
        Image,
        ImageCaption,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
        Indent,
        Italic,
        Link,
        List,
        MediaEmbed,
        Paragraph,
        PasteFromOffice,
        SourceEditing,
        Table,
        TableToolbar,
        TextTransformation,
        Alignment,
        AutoImage,
        CKFinderUploadAdapter,
        CloudServices,
        FindAndReplace,
        FontBackgroundColor,
        FontColor,
        FontFamily,
        FontSize,
        Highlight,
        ImageResize,
        IndentBlock,
        RemoveFormat,
        SpecialCharacters,
        SpecialCharactersArrows,
        SpecialCharactersCurrency,
        SpecialCharactersEssentials,
        SpecialCharactersLatin,
        SpecialCharactersMathematical,
        SpecialCharactersText,
        TableCaption,
        TableCellProperties,
        TableProperties,
        Underline,
        WordCount,
      ],
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'underline',
          // 'link',
          // 'bulletedList',
          // 'numberedList',
          '|',
          'outdent',
          'indent',
          '|',
          // 'imageUpload',
          'blockQuote',
          'insertTable',
          // 'mediaEmbed',
          'undo',
          'redo',
          'alignment',
          'code',
          'findAndReplace',
          // 'fontBackgroundColor',
          // 'fontColor',
          'fontFamily',
          'fontSize',
          'highlight',
          'htmlEmbed',
          'removeFormat',
          'sourceEditing',
          // 'specialCharacters'
        ],
      },
      image: {
        toolbar: [
          'imageTextAlternative',
          'imageStyle:inline',
          'imageStyle:block',
          'imageStyle:side',
        ],
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          // 'tableCellProperties',
          // 'tableProperties'
        ],
      },
      htmlEmbed: {
        showPreviews: true,
        // sanitizeHtml: (inputHtml) => {
        //   // Strip unsafe elements and attributes, e.g.:
        //   // the `<script>` elements and `on*` attributes.
        //   const outputHtml = sanitize(inputHtml)

        //   return {
        //     html: outputHtml,
        //     // true or false depending on whether the sanitizer stripped anything.
        //     hasChanged: true
        //   }
        // }
      },
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Paragraph',
            class: 'ck-heading_paragraph',
          },
          {
            model: 'heading1',
            view: 'h1',
            title: 'Heading 1',
            class: 'ck-heading_heading1',
          },
          {
            model: 'heading2',
            view: 'h2',
            title: 'Heading 2',
            class: 'ck-heading_heading2',
          },
        ],
      },
    }

    return {
      showOverlay,
      refFormObserver,
      companyVariables,
      patientVariables,
      patientAddressVariables,
      patientResponsibleVariables,
      patientResponsibleAddressVariables,
      typeOptions,
      editor,
      editorConfig,

      onReady,
      copyVariable,
      save,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.document-template-editor .ck-editor__editable {
  height: 950px;
}

.dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }

  .ck:not(.ck-color-grid__tile) {
    background: #283046 !important;
    color: white !important;
    border-color: #404656 !important;
  }
}
</style>
