<template>
  <div>
    <b-sidebar
      id="add-new-user-sidebar"
      :visible="isDocumentTemplateSidebarActive"
      :sidebar-class="{
        'expanded-menu': !isVerticalMenuCollapsed,
        'retracted-menu': isVerticalMenuCollapsed,
        'no-xl' : currentBreakPoint && currentBreakPoint !== 'xl'
      }"
      bg-variant="white"
      no-header
      z-index="10"
      right
      @hidden="$emit('reset')"
      @change="(val) => $emit('update:is-document-template-sidebar-active', val)"
    >

      <template #default="{ hide }">
        <b-overlay
          :show="showOverlay"
          rounded="sm"
          no-fade
        >
          <!-- Header -->
          <div
            style="padding-top: 85px"
            class="d-flex content-sidebar-header"
          >
            <feather-icon
              class="cursor-pointer m-1"
              icon="ArrowLeftIcon"
              size="25"
              @click="hide"
            />
          </div>

          <div class="d-flex justify-content-between align-items-center px-2 py-1">
            <h5 class="mb-0">
              Cadastro de Documento
            </h5>
          </div>

          <div>
            <b-form
              class="p-2"
              @submit.prevent="onSubmit"
              @reset.prevent="resetForm"
            >
              <Main
                :document-template="documentTemplate"
                @refreshData="$emit('refreshData')"
                @close="$emit('update:is-document-template-sidebar-active', false)"
              />
            </b-form>
          </div>
        </b-overlay>
      </template>

    </b-sidebar>
  </div>
</template>

<script>
import {
  BOverlay, BSidebar, BForm,
} from 'bootstrap-vue'
import {
  required, alphaNum, email, cpfCnpj,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import { mask } from 'vue-the-mask'
import Main from './side-bar-content/Main.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BOverlay,

    Main,
  },
  directives: {
    Ripple,
    mask,
  },
  model: {
    prop: 'isDocumentTemplateSidebarActive',
    event: 'update:is-document-template-sidebar-active',
  },
  props: {
    isDocumentTemplateSidebarActive: {
      type: Boolean,
      required: true,
    },
    documentTemplate: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      cpfCnpj,
      mouseOver: null,
      showOverlay: false,
    }
  },
  computed: {
    isVerticalMenuCollapsed() {
      return this.$store.state.verticalMenu.isVerticalMenuCollapsed
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.expanded-menu {
  width: calc(100% - 288px);
  }

.retracted-menu {
  width: calc(100% - 108px);
  }

.no-xl {
  width: calc(100% - 28px);
}

// resolve a cor do overlay
.dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }
}

</style>
